import { Container } from "../../../UI/container/Container";
import { Grid } from "@mantine/core";
import { MyButton } from "../../../UI/my-button/MyButton";
import { useColors } from "../../../../hooks/colors/useColors";
import { HandWritingH1 } from "../../../UI/hand-writing/HandWritingH1";
import { useBlogList } from "../../../../hooks/react-query/useBlogList";

import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { Fragment } from "react";
import { BlogCol } from "./blog-col";

export function BlogSection() {
  const { colors } = useColors();
  const { blogs, getFirstNArticles } = useBlogList();
  const router = useRouter();
  const { t } = useTranslation("common");

  return (
      <Container noHeight>
        <Grid>
          <Grid.Col span={12}>
            <HandWritingH1 size={60}>{t("blog.name")}</HandWritingH1>
            <HandWritingH1>{t("lastArticles")}</HandWritingH1>
          </Grid.Col>
          {
            <Fragment>
              {blogs &&
                  Object.keys(getFirstNArticles(blogs, 6)).map((key, index) => {
                    const blogPost = blogs[key];
                    return <BlogCol key={index} id={key} data={blogPost} />;
                  })}
            </Fragment>
          }

          <Grid.Col span={12} className="flex justify-center py-6">
            <MyButton
                rounded
                onClick={async () => await router.push("/blog")}
                title={t("otherArticles")}
                color={colors.primary}
                textColor={colors.white}
            />
          </Grid.Col>
        </Grid>
      </Container>
  );
}

// import { Container } from "../../../UI/container/Container";
// import { Grid } from "@mantine/core";
// import { useColors } from "../../../../hooks/colors/useColors";
// import { HandWritingH1 } from "../../../UI/hand-writing/HandWritingH1";
// import {
//   BlogData,
//   useBlogList,
// } from "../../../../hooks/react-query/useBlogList";
//
// import { useRouter } from "next/router";
// import { useTranslation } from "next-i18next";
// import { Fragment, useState } from "react";
// import { useSpecialistList } from "../../../../hooks/react-query/useSpecialistList";
// import { BlogCard } from "./BlogCard";
// import useAsyncEffect from "use-async-effect";
// import { ButtonLink } from "../../../UI/button-link/ButtonLink";
//
// export function BlogSection() {
//   const { colors } = useColors();
//
//   const router = useRouter();
//   const { t } = useTranslation("common");
//   const { specialists } = useSpecialistList();
//   const { fetchPostsFromWP } = useBlogList();
//
//   const [blogs, setBlogs] = useState<BlogData>();
//
//   useAsyncEffect(async () => {
//     if (router.locale) {
//       const fetchedBlogs = await fetchPostsFromWP(6);
//       setBlogs(fetchedBlogs);
//     }
//   }, [router.locale]);
//
//   return (
//     <Container noHeight>
//       <Grid>
//         <Grid.Col span={12}>
//           <HandWritingH1 size={60}>{t("blog.name")}</HandWritingH1>
//           <HandWritingH1>{t("lastArticles")}</HandWritingH1>
//         </Grid.Col>
//         {
//           <Fragment>
//             {blogs &&
//               specialists &&
//               blogs.data.posts.nodes.map((post, index) => {
//                 return <BlogCard key={index} data={post} />;
//               })}
//           </Fragment>
//         }
//
//         <Grid.Col span={12} className="flex justify-center py-6">
//           <ButtonLink
//             rounded
//             href="/blog"
//             title={t("otherArticles")}
//             color={colors.primary}
//             textColor="white"
//           />
//         </Grid.Col>
//       </Grid>
//     </Container>
//   );
// }
