import { BlogCard } from "./BlogCard";
import { BlogData } from "../../../../hooks/react-query/useBlogList";
import { Grid } from "@mantine/core";

type InputProps = {
    id: string;
    data: BlogData;
};

export function BlogCol(props: InputProps) {
    const { data, id } = props;

    return (
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }} className="flex justify-center">
            <BlogCard data={data} slug={id} />
        </Grid.Col>
    );
}